import {
  CartItem as CCCartItem,
  CartModifier,
  ItemType,
  Placement,
  Portion
} from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { PizzaUserSelections } from '@/builders/pizza/dataTransformers/builderTypes';
import toIngredient from '@/builders/pizza/dataTransformers/toIngredient';
import { CartItem } from '@/domain/cart/types';
import { PizzaBuilderOptions } from '@/graphql/dynamicQueries/pizza';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';

type Toppings = { meatToppings: CartModifier[]; veggieToppings: CartModifier[] };
const cartItemVariantCode = (selectedItem: CartItem | CCCartItem) => ('variantCode' in selectedItem ? selectedItem.variantCode : '');

const formatPlacementAndPortion = (topping: CartModifier, slotCode?: string): CartModifier => {
  let portion = 'REGULAR';
  const isLight = topping?.weightName?.includes('LIGHT');
  const isExtra = topping?.weightName?.includes('EXTRA');
  // for ui.
  if (isLight) portion = 'LIGHT';
  if (isExtra) portion = 'EXTRA';

  return {
    ...topping,
    placement: (topping?.placement ?? 'WHOLE').toLowerCase() as Placement,
    portion: portion.toLowerCase() as Portion,
    slotCode
  };
};

const selectToppings = (cartItemModifierSet: CartModifier[], pizzaOptions: PizzaBuilderOptions): Toppings => {
  const toppingsModifierSet = cartItemModifierSet.find((modSet) => modSet.id === 'slot_pizza_toppings');
  // splits toppings into meat and veggie depending on which type it is.
  return (toppingsModifierSet?.modifiers ?? []).reduce(
    (acc, currentTopping) => {
      const refinedTopping = formatPlacementAndPortion(currentTopping, toppingsModifierSet?.slotCode ?? '');
      const isMeatTopping = Boolean(pizzaOptions?.meatToppings?.find((topping) => topping.id === currentTopping.id));
      if (refinedTopping) acc[isMeatTopping ? 'meatToppings' : 'veggieToppings'].push(refinedTopping);
      return acc;
    },
    { meatToppings: [] as CartModifier[], veggieToppings: [] as CartModifier[] }
  );
};

const selectCheese = (selectedItem: CCCartItem, pizzaOptions: PizzaBuilderOptions) => {
  const cheeseModifiers = selectedItem?.modifiers?.find((modSet) => modSet.id === 'slot_pizza_cheese');
  if (!cheeseModifiers?.modifiers?.length || !pizzaOptions?.cheeses?.length) return undefined;
  const cheeseModifierWeightCode = cheeseModifiers?.modifiers[0].weightCode;

  const selectedCheese = pizzaOptions.cheeses
    .find((cheeseSet) => cheeseSet.portions.some((portion) => portion.weightCode === cheeseModifierWeightCode))
    ?.portions.find((portion) => portion.weightCode === cheeseModifierWeightCode);

  return toIngredient(selectedCheese);
};

const selectSauce = (cartItemModifierSet: CartModifier[]) => {
  const sauceModifierSet = cartItemModifierSet.find((modSet) => modSet.id === 'slot_pizza_sauce');
  if (!sauceModifierSet?.modifiers?.length || !sauceModifierSet?.slotCode) return undefined;
  const selectedSauce = formatPlacementAndPortion(sauceModifierSet.modifiers[0], sauceModifierSet.slotCode);
  return selectedSauce;
};

const selectSize = (pizzaOptions: PizzaBuilderOptions, variantCodeArray: string[]) => {
  const sizeCode = variantCodeArray.find((str) => str.includes('SIZE'));
  if (!sizeCode) return undefined;
  const sizeMod = pizzaOptions?.sizes?.find((size) => size.id.includes(sizeCode));

  const sizeSelectedOptions: CartModifier = {
    id: sizeMod?.id ?? '',
    name: sizeMod?.name ?? '',
    price: sizeMod?.price ?? 0,
    type: sizeMod?.type as ItemType
  };
  return sizeSelectedOptions;
};

const selectCrust = (selectedItem: CCCartItem, pizzaOptions: PizzaBuilderOptions) => {
  const { variantCode } = selectedItem;
  const selectedCrust = pizzaOptions?.crusts?.find((crust) => crust.variantCode === variantCode);
  return selectedCrust;
};

export const extractYumPizzaUserSelections = (
  selectedItem: CCCartItem,
  pizzaOptions: PizzaBuilderOptions
): PizzaUserSelections => {
  // Break Up Variant Code
  const selectedItemVariantCode = cartItemVariantCode(selectedItem);
  const variantCodeArray = selectedItemVariantCode?.split('_') ?? [];

  const optimizely = getOrInitializeOptimizely();
  const useSaveCrustDecision = optimizely?.isFeatureEnabled('fr-web-3500-yum-edit-builder-save-crust') || false;

  const selectedToppings = selectToppings(selectedItem?.modifiers ?? [], pizzaOptions);
  const selectedSauce = selectSauce(selectedItem?.modifiers ?? []);
  const selectedCheese = selectCheese(selectedItem, pizzaOptions);
  const selectedSize = selectSize(pizzaOptions, variantCodeArray);
  const selectedCrust = selectCrust(selectedItem, pizzaOptions);

  return {
    finisher: null,
    cheese: selectedCheese,
    sauce: selectedSauce,
    size: selectedSize,
    meatToppings: selectedToppings.meatToppings,
    veggieToppings: selectedToppings.veggieToppings,
    specialInstructions: selectedItem.specialInstructions,
    crust: useSaveCrustDecision ? selectedCrust : undefined
  } as unknown as PizzaUserSelections;
};
