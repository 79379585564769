import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { ActionCreators } from 'redux-undo';
import { userSelectionsSelector } from '@/builders/deals/slice/userSelections.slice';
import { RootState } from '@/rootStateTypes';
import StepType from '@/builders/deals/StepType';

const useDealExitFunction = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const dealStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const hasPastState: boolean = useSelector(
    (state: RootState) => state.domain.deal.userSelections.past.length > 0
  );

  const isMultiRecipe = dealStep?.type === StepType.MULTI_PIZZA_RECIPE;
  const isOnRecipeStep = !!dealStep?.recipeId;

  return () => {
    if (hasPastState && (isMultiRecipe || isOnRecipeStep)) {
      return dispatch(ActionCreators.undo());
    }
    return router.back();
  };
};

export default useDealExitFunction;
