import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { Device, HiddenOnDevice } from '../../../common/ResponsiveContext';
import { CurrentStep, DealStep } from '../slice/dealTypes';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { onDealStepClick } from '@/builders/deals/analytics';
import StepType from '../StepType';
import ProductId from '@/common/ProductId';
import { MELT, PIZZA } from '@/domain/constants';
import { updateDealStep, userSelectionsSelector } from '../slice/userSelections.slice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import CenteredContainer from '@/common/CenteredContainer';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import AddDealToCart from '../AddToDeal/AddDealToCart';
import { useDealDetailsStyles } from './DealDetails.styles';
import AddToCartFooter from '@/common/AddToCartFooter';
import { EditBuilderSkeleton, useEditBuilderSkeleton } from '@/common/EditBuilderSkeleton';
import IndividualDealStep from '../steps/IndividualDealStep';
import decodeEntities from '@/graphql/helpers/decodeEntities';

type BuilderStep = Pick<
CurrentStep,
| 'type'
| 'recipeId'
| 'recipeType'
| 'id'
| 'name'
| 'description'
| 'price'
| 'recipeOptions'
| 'index'
>;

interface Props {
  isEditFlow?: boolean;
}

export const DealDetails = ({ isEditFlow = false }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const deal = useSelector(dealSelectors.selectDeal);
  const getRecipeById = useSelector(userSelectionsSelector.selectSelectedRecipeByStepId);
  const analytics = useAnalytics();
  const classes = useDealDetailsStyles();
  const showEditBuilderSkeleton = useEditBuilderSkeleton();
  const [multiStepSimplificationDecision] = useDecision('exp-abt-58-multi-step-builder-simplification');

  const showAddToCart = deal?.data.steps.length === deal?.userSelections?.present?.recipes?.length;
  const summary = deal && {
    id: deal.data.id,
    name: decodeEntities(deal.data.name),
    image: deal.data.image
  };

  const onStepClick = (dealStep: DealStep, index: number) => {
    const {
      id, name, description, required, recipeOptions, price
    } = dealStep;
    const stepNumber = (deal?.data.steps.indexOf(dealStep) ?? 0) + 1;
    const item = getRecipeById(id, index)?.item;
    const isMultiRecipe = recipeOptions && recipeOptions.length > 1;
    const [{ id: recipeId, type: recipeType, name: recipeName }] = recipeOptions || [];
    const dealGlobalId = new ProductId(deal?.data.id || '').globalId;

    analytics.push(() => onDealStepClick({
      step_name: name || '',
      deal_id: dealGlobalId || '',
      deal_name: deal?.data.name || '',
      ...(!isMultiRecipe ? { product_name_hit: recipeName || '' } : {}),
      step_number: stepNumber
    }));

    const pizzaStepType = isMultiRecipe ? StepType.MULTI_PIZZA_RECIPE : StepType.SINGLE_PIZZA_RECIPE;
    const currentStepType = (recipeType === PIZZA || recipeType === MELT) ? pizzaStepType : StepType.MENU_RECIPE;

    if (item) {
      const { globalId } = new ProductId(item.id || '');
      const step: BuilderStep = {
        type:
          (item.type === PIZZA || item.type === MELT) ? StepType.SINGLE_PIZZA_RECIPE : currentStepType,
        recipeId: globalId,
        recipeType,
        recipeOptions,
        id,
        name,
        description,
        price,
        index
      };

      dispatch(updateDealStep(step));
    } else {
      const step: CurrentStep = {
        type: currentStepType,
        recipeId: isMultiRecipe ? null : recipeId,
        recipeType: isMultiRecipe ? null : recipeType,
        name,
        description,
        required,
        id,
        price,
        recipeOptions: dealStep?.recipeOptions || [],
        index
      };

      dispatch(updateDealStep(step));
    }
  };

  if (!deal || !deal.data.id) {
    return <></>;
  }

  const DealDetailsHeader = (): JSX.Element => (
    <>
      <Grid item md={7}>
        <Typography variant="h1" className={classes.dealName} data-testid="deal-name">{summary?.name}</Typography>
        <Typography variant="h2" className={classes.subHeader}>Build your deal</Typography>
      </Grid>
    </>
  );

  return (
    <>
      <HiddenOnDevice {...Device.DESKTOP}>
        <DealDetailsHeader />
      </HiddenOnDevice>
      <CenteredContainer>
        <Grid className={classes.root} data-testid="build-your-deal-desktop">
          <HiddenOnDevice {...Device.MOBILE}>
            <DealDetailsHeader />
          </HiddenOnDevice>
          <Grid container wrap="nowrap">
            <Grid item className={classes.cardBox}>
              {showEditBuilderSkeleton
                ? <EditBuilderSkeleton height="300px" />
                : (
                  <Grid data-testid="deal-steps">{deal.data.steps.map((step, index) => (
                    <IndividualDealStep key={step.id} step={step} index={index} onStepClick={onStepClick} rules={deal.data.rules} multiStepDecisionEnabled={multiStepSimplificationDecision.enabled} />
                  ))}
                  </Grid>
                ) }
              <CaloriesDisclaimer />
            </Grid>
            <HiddenOnDevice {...Device.MOBILE}>
              <Grid item className={classes.imageGrid}>
                <img
                  loading="lazy"
                  src={summary?.image}
                  alt=""
                  className={classes.img}
                />
              </Grid>
            </HiddenOnDevice>
          </Grid>
        </Grid>
      </CenteredContainer>
      {showAddToCart && (
      <AddToCartFooter>
        <CenteredContainer>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.btnContainer}
          >
            <Grid item>
              <AddDealToCart isEditFlow={isEditFlow} />
            </Grid>
          </Grid>
        </CenteredContainer>
      </AddToCartFooter>
      )}
    </>
  );
};

export default DealDetails;
