/* eslint-disable no-param-reassign */
import { compose } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';
import { clearDealToDisplay, setDealToDisplay } from './userSelections.slice';

function domain(state: RootState): { id: string | null } {
  return state.domain.deal.userSelections.present.dealToDisplay;
}

const identity = <T>(x: T) => x;

export const selectDealToDisplay = compose(identity, domain);

export { setDealToDisplay, clearDealToDisplay };
