import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import useAddToCart from '@/cart/hooks/useAddToCart';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { localizationSelectors } from '@/localization/localizationSelectors';
import telemetry from '@/telemetry';
import Routes from '@/router/routes';
import { openModal } from '@/localization/actions';
import {
  cartErrorModalDetails,
  getDealErrorModalDetails,
  NOT_COMBINABLE_DEALS_TITLE
} from '@/common/Modal/modalDetails';

interface Props {
  isEditFlow?: boolean;
}

const OrderLevelDeal = ({ isEditFlow = false }: Props): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { addToCart } = useAddToCart();
  const deal = useSelector(dealSelectors.selectDeal);
  const localizationToken = useSelector(
    localizationSelectors.localizationToken
  );
  const dealId = deal?.data.id;

  const onSuccessfulRequest = () => {
    telemetry.addCustomEvent('added-order-level-deal', { dealId });
    router.push(Routes.HOME);
  };

  const onFailedRequest = (err? : Error) => {
    let dealErrorModalDetails;

    if (err && err.message) {
      dealErrorModalDetails = getDealErrorModalDetails(NOT_COMBINABLE_DEALS_TITLE, err.message);
    }
    dispatch(openModal(dealErrorModalDetails || cartErrorModalDetails));
    router.push(Routes.DEALS);
  };

  useEffect(() => {
    if (dealId && localizationToken) {
      addToCart(deal, onSuccessfulRequest, onFailedRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizationToken, dealId]);

  return <div data-testid="order-level-deal" />;
};

export default OrderLevelDeal;
