import { makeStyles } from '@material-ui/core';
import colors from '../../../common/colors';

export const useDesktopStyles = makeStyles(() => ({
  caratIcon: {
    height: '17px',
    alignSelf: 'center',
    color: colors.gray900
  },
  cardRoot: {
    padding: '20px 0 18px 0',
    boxShadow: 'none',
    borderTop: `1px solid ${colors.gray400}`,
    '&:last-child': {
      padding: '20px 0 18px 0',
      marginBottom: '-1px',
      borderBottom: `1px solid ${colors.gray400}`
    }
  },
  cardContent: {
    '&.MuiCardContent-root': {
      padding: 0
    }
  },
  cardSummary: {
    marginBottom: '0.5em'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  checkboxbox: {
    padding: '0px'
  },
  checkboxText: {
    paddingLeft: '3px',
    paddingRight: '8px'
  }
}));

export default { useDesktopStyles };
