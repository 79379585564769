import { useSelector } from 'react-redux';
import { userSelectionsSelector } from '../../slice/userSelections.slice';
import usePizzaValidator from '../../../pizza/hooks/usePizzaValidator';
import { MELT, PIZZA } from '@/domain/constants';

export default function useDealValidator(): boolean {
  const { recipeType } = useSelector(userSelectionsSelector.selectCurrentStep) ?? {};
  const isValidPizza = usePizzaValidator();
  if (recipeType === PIZZA || recipeType === MELT) return isValidPizza;

  return false;
}
