import { useSelector } from 'react-redux';
import { isEmpty } from '@/utils';
import {
  CrustIdWithUpcharge,
  CrustOption,
  PizzaIngredientOption,
  PizzaOptions
} from '@/builders/pizza/dataTransformers/builderTypes';
import { userSelectionsSelector } from '../slice/userSelections.slice';
import { dealSelectors } from '../slice/deal.slice';
import { DealRuleItem, SelectedRecipe } from '../slice/dealTypes';
import {
  DisplayableModifier,
  DisplayableProduct,
  Product
} from '@/domain/product/types';
import ProductId from '@/common/ProductId';
import { UseDisplayableProductProps } from '@/menu/categories/useDisplayableProduct/types';
import { SIZE } from '@/domain/constants';

type SizesAndCrustsOptions = Pick<PizzaOptions, 'sizes' | 'crusts'>;

type RuleIds = DealRuleItem['id'][] | undefined;
interface CrustsAndSizesProps {
  pizzaOptions: PizzaOptions;
  crustIds: CrustIdWithUpcharge[] | undefined;
  sizeIds: RuleIds;
}

const getOptionsByIds = <T extends PizzaIngredientOption>(
  options: T[],
  ruleIds: RuleIds
): T[] => options
    .filter((option) => ruleIds?.some((id) => option.id?.includes(id || '')));

const getOptions = <T extends PizzaIngredientOption>(
  options: T[],
  rules: CrustIdWithUpcharge[] | undefined
): T[] => options
    .filter((option) => rules?.some((rule) => option.id?.includes(rule.id || '')));

const getCrustIdsandPrice = (crustOptions: CrustOption[],
  crustIds: CrustIdWithUpcharge[] | undefined) => crustOptions.map((opt) => {
  const currentOpt = opt;
  const matchedPriceObj = crustIds?.find((priceObj) => {
    if (priceObj && priceObj.id) {
      return currentOpt?.id?.includes(priceObj.id);
    }
    return undefined;
  });
  currentOpt.price = matchedPriceObj?.price;
  return currentOpt;
});

const filteredByRuleSizeIds = ({
  pizzaOptions: { crusts, sizes },
  sizeIds
}: CrustsAndSizesProps): SizesAndCrustsOptions => {
  const s = sizes && sizeIds?.length ? getOptionsByIds(sizes, sizeIds) : [];
  const target = s[0].id;
  const c = target && crusts
    ? crusts.filter((crust) => crust.sizeId.includes(target))
    : [];
  return {
    sizes: s,
    crusts: c
  };
};

const filteredByRuleCrustAndSizeId = ({
  pizzaOptions: { crusts, sizes },
  sizeIds,
  crustIds
}: CrustsAndSizesProps): SizesAndCrustsOptions => {
  const s = sizes ? getOptionsByIds(sizes, sizeIds) : [];

  const isCrustAvailableForSize = (
    crust: CrustOption
  ) => s.find((size) => crust.sizeId.includes(size.id || ''));

  const c = crusts ? getOptions(crusts, crustIds) : [];
  const updatedCrusts = getCrustIdsandPrice(c, crustIds);
  const crustsAvailables = updatedCrusts.filter(isCrustAvailableForSize);

  return {
    crusts: crustsAvailables,
    sizes: s
  };
};

const filteredByRuleCrustId = ({
  pizzaOptions: { crusts, sizes },
  crustIds
}: CrustsAndSizesProps): SizesAndCrustsOptions => {
  const c = crusts ? getOptions(crusts, crustIds) : [];
  const updatedCrusts = getCrustIdsandPrice(c, crustIds);
  const sizeIds = updatedCrusts.map((crust) => crust.sizeId);
  const s = sizes
    ? sizes.filter((size) => size.id && sizeIds.includes(size.id))
    : [];

  return {
    crusts: updatedCrusts,
    sizes: s
  };
};

const getCrustsAndSizes = (
  props: CrustsAndSizesProps
): SizesAndCrustsOptions => {
  const { pizzaOptions, crustIds, sizeIds } = props;

  const haveSizes = sizeIds?.length;
  const haveCrusts = crustIds?.length;

  if (haveCrusts && haveSizes) return filteredByRuleCrustAndSizeId(props);

  if (haveCrusts && !haveSizes) return filteredByRuleCrustId(props);

  if (!haveCrusts && haveSizes) return filteredByRuleSizeIds(props);

  const { crusts, sizes } = pizzaOptions;
  return {
    crusts,
    sizes
  };
};

const hideCrustStepCarat = (
  { crusts, sizes }: SizesAndCrustsOptions,
  pizzaCrustIds: RuleIds,
  pizzaSizeIds: RuleIds
): boolean => {
  const hasOneSizeAndOneCrustPermittedByDealRules = pizzaCrustIds?.length === 1
    && pizzaSizeIds?.length === 1;

  const hasOneSizeAndOneCrustOptions = crusts && crusts.length <= 1 && sizes && sizes.length <= 1;
  return Boolean(
    hasOneSizeAndOneCrustPermittedByDealRules || hasOneSizeAndOneCrustOptions
  );
};

const useApplyDealRules = (
  pizzaOptions: PizzaOptions | null
): PizzaOptions | null => {
  const { name: currentDealStepName } = useSelector(
    userSelectionsSelector.selectCurrentStep
  ) ?? {};
  const crustIds = useSelector(dealSelectors.selectCrustIdandUpchargeForDeal);
  const crustIdsWithoutPrice = useSelector(dealSelectors.selectCrustIdForDeal);
  const sizeIds = useSelector(dealSelectors.selectSizeIdForDeal);

  const recipeDefaultToppingsCount = useSelector(
    dealSelectors.selectCustomizationsIncluded
  );

  if (!pizzaOptions) return pizzaOptions;
  const crustsAndSizes = getCrustsAndSizes({ pizzaOptions, crustIds, sizeIds });

  return {
    ...pizzaOptions,
    summary: {
      ...pizzaOptions.summary,
      displayName: pizzaOptions.summary.name,
      recipeDefaultToppingsCount,
      name: currentDealStepName,
      showTotalPrice: false,
      hideCrustStepCarat: hideCrustStepCarat(crustsAndSizes, crustIdsWithoutPrice, sizeIds)
    },
    ...crustsAndSizes
  };
};

const filterByRuleSizeIds = (
  item: DisplayableProduct,
  sizeIds: RuleIds
) => {
  const filteredSizes = item?.sizes
    ?.filter((size) => sizeIds?.some((id) => id && new ProductId(size.id).globalId?.includes(id)));
  return filteredSizes && filteredSizes.length > 0 ? filteredSizes : item.sizes;
};

const filterByRuleOptionIds = (
  item: DisplayableProduct,
  optionIds: RuleIds
) => {
  const entries = item?.additionalOptions && Object.entries(item.additionalOptions);

  const optionsFiltered = entries
    ?.filter((entry): entry is [string, DisplayableModifier[]] => !!entry[1])
    .map((entry) => {
      const [key, value] = entry;
      const filtered = value.filter((modifier) => optionIds?.some(
        (id) => id && modifier.id?.includes(id)
      ));
      return {
        [key]: filtered.length > 0 ? filtered : value
      };
    });

  return optionsFiltered?.length && Object.assign({}, ...optionsFiltered);
};

const getSizesAndOptions = (
  item: DisplayableProduct,
  restrictedSizeIds: RuleIds,
  restrictedOptionIds: RuleIds
) => {
  const hasRestrictedSizes = restrictedSizeIds?.length;
  const hasRestrictedOptions = restrictedOptionIds?.length;
  let { sizes, additionalOptions } = item;

  if (hasRestrictedSizes) {
    sizes = filterByRuleSizeIds(item, restrictedSizeIds);
  }

  if (hasRestrictedOptions) {
    additionalOptions = filterByRuleOptionIds(item, restrictedOptionIds);
  }

  return {
    sizes,
    additionalOptions
  };
};

interface CommonProps {
  item: DisplayableProduct;
  recipeSelected: Product;
}

interface PropsWithSizeName extends CommonProps {
  sizeName: string;
}

const getSelectedSize = ({ item, recipeSelected }: CommonProps) => {
  const sizeSelectedId = recipeSelected
    .selectedModifiers
    .find((itemModifier) => itemModifier.type === SIZE)?.id;
  return item?.sizes && item.sizes
    .find((itemModifier) => itemModifier.id === sizeSelectedId);
};

const getSelectedAdditionalOption = ({ item, recipeSelected, sizeName }: PropsWithSizeName) => {
  const selectedAdlOption = recipeSelected
    .selectedModifiers
    .find((itemModifier) => itemModifier.type !== SIZE);

  return item?.additionalOptions && item
    ?.additionalOptions[sizeName]
    ?.find((itemModifier) => itemModifier.id === selectedAdlOption?.id);
};

type PreSelections = Pick<
UseDisplayableProductProps,
'preSelectedSize' | 'preSelectedAdditionalOption' | 'preSelectedQuantity'
>;
const getSelectedOptions = (
  item: DisplayableProduct,
  { item: recipeSelected }: SelectedRecipe
): PreSelections | undefined => {
  if (item.id !== recipeSelected.id) return undefined;

  const props = {
    item,
    recipeSelected: recipeSelected as Product
  };
  const preSelectedSize = getSelectedSize(props);

  if (!preSelectedSize) {
    return undefined;
  }

  const preSelectedAdditionalOption = getSelectedAdditionalOption({
    ...props,
    sizeName: preSelectedSize.name
  });

  return {
    preSelectedSize,
    preSelectedAdditionalOption,
    preSelectedQuantity: recipeSelected.quantity
  };
};

const productHasAdditionalOptions = (item: DisplayableProduct): boolean => (
  (item.additionalOptions && Object.values(item.additionalOptions).flat().length > 0) || false
);

const areAllProductOptionsOOS = (options: DisplayableModifier[]): boolean => (
  isEmpty(options) || !options.find((option) => !option.isOutOfStock)
);

export const useApplyDealRulesForNonPizzaProduct = (
  data: DisplayableProduct[] = []
): Array<DisplayableProduct & PreSelections> => {
  const optionIds = useSelector(dealSelectors.selectCrustIdForDeal);
  const sizeIds = useSelector(dealSelectors.selectSizeIdForDeal);
  const currentStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const selectSelectedRecipeByStepId = useSelector(
    userSelectionsSelector.selectSelectedRecipeByStepId
  );
  const recipesSelected = currentStep && currentStep.index > -1 && selectSelectedRecipeByStepId(currentStep.id || '', currentStep.index);

  return data.map((item) => {
    const hasAdditionalOptions = productHasAdditionalOptions(item);
    const sizeAndOptions = getSizesAndOptions(item, sizeIds, optionIds);
    const selectedOptions = recipesSelected ? getSelectedOptions(item, recipesSelected) : {};

    const unrestrictedAdditionalOptions = sizeAndOptions?.additionalOptions && Object
      .values(sizeAndOptions.additionalOptions)
      .flat()
      .filter((additionalOptions): additionalOptions is DisplayableModifier => !!additionalOptions);

    const isOutOfStock = item.isOutOfStock || (sizeAndOptions?.sizes && areAllProductOptionsOOS(sizeAndOptions.sizes))
      || (hasAdditionalOptions && unrestrictedAdditionalOptions && areAllProductOptionsOOS(unrestrictedAdditionalOptions));

    return {
      ...item,
      ...sizeAndOptions,
      ...selectedOptions,
      isOutOfStock
    };
  });
};

export default useApplyDealRules;
