import React, { useEffect, useState } from 'react';
import { sortBy } from 'remeda';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { ChildDealStep } from '@/builders/deals/slice/dealTypes';
import ProductId from '@/common/ProductId';
import { formatTestId } from '@/common/string-formatter';
import MenuTile from '@/menu/MenuTile';
import Menu from '@/menu/Menu';
import telemetry from '@/telemetry';
import { setDealToDisplay } from '@/builders/deals/slice/dealToDisplay.slice';
import { onChildDealClick, onParentDealPageLoad } from '@/builders/deals/analytics/analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

interface ParentChildDealMenuProps {
  title: string;
  items: ChildDealStep[];
}

const ParentChildDealMenu = (props: ParentChildDealMenuProps): JSX.Element => {
  const { title, items } = props;
  const dispatch = useDispatch();
  const sortedItems = sortBy(items, (item) => item.id);
  const analytics = useAnalytics();
  const { deal } = analytics.analyticsDataModel;
  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    telemetry.addCustomEvent('parent-child-pizza-deal-view-page');
  }, []);

  useEffect(() => {
    if (deal.deal_id && deal.deal_name && !pageLoadAnalyticsPushed) {
      analytics.push(() => onParentDealPageLoad(deal.deal_id || '', deal.deal_name || '', sortedItems));
      setPageLoadAnalyticsPushed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  function handleClickOnChildDeal(globalId: string, index: number) {
    const product = sortedItems[index];
    if (deal.deal_id && deal.deal_name && product) {
      analytics.push(
        () => onChildDealClick(deal.deal_id || '', deal.deal_name || '', globalId, product, index)
      );
    }
    dispatch(setDealToDisplay({ dealId: globalId }));
  }

  return (
    <>
      <Menu
        itemsTestId="deal"
        title="Deals"
        subtitle={title}
        showMoreInformation={false}
        caloriesDisclaimer
      >
        <div data-testid="deal">
          {sortedItems.map((tile, index) => {
            const {
              id, name, description, outOfStock, image = '', price, allergenDisclaimer
            } = tile;
            const { globalId } = new ProductId(id);

            const tileName = formatTestId(name);

            return (
              <Grid
                container
                data-testid={`menu-tile-container-${tileName}`}
                key={globalId}
              >
                <MenuTile
                  name={name}
                  title={title}
                  price={price}
                  description={description || ''}
                  allergenDisclaimer={allergenDisclaimer}
                  testId={`menu-tile-${tileName}`}
                  handleClick={() => handleClickOnChildDeal(id, index)}
                  imageURL={image}
                  hasLinkEnabled={!outOfStock}
                  productDescription={description === null ? undefined : description}
                  hideDescription
                  badges={{ soldOut: outOfStock }}
                />
              </Grid>
            );
          })}
        </div>
      </Menu>
    </>
  );
};

export default ParentChildDealMenu;
