import { useSelector } from 'react-redux';
import { selectOccasion, selectStoreTimeZone } from '@/localization/selectors';
import { OccasionApi } from '@/localization/constants';

export interface CheckAvailabilityVariables {
  occasion: OccasionApi;
  storeTimeZone: string;
}

export default function useCheckAvailabilityVariables(): CheckAvailabilityVariables {
  const occasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const storeTimeZone = useSelector(selectStoreTimeZone);

  return { occasion: OccasionApi[occasion], storeTimeZone };
}
