import gql from 'graphql-tag';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import ItemFragment from '../queries/fragments/item';
import {
  transformCategoryProductToDisplayableProduct
} from '../helpers/categoryProductsToDisplayableProductsTransformer';
import { DisplayableProduct, isDisplayableProduct } from '@/domain/product/types';
import { ProductsByIdQueryResponse } from '../types/fragment/Products';
import { TypedQueryWithParser } from '../types/TypedQuery';
import { CheckAvailabilityVariables } from '../hooks/variables/useCheckAvailabilityVariables';

const parser = (
  data: ProductsByIdQueryResponse,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): DisplayableProduct[] => {
  const products = data?.Homepage?.menu || {};
  return Object.values(products)
    .filter((item) => typeof item !== 'string')
    .map((item) => transformCategoryProductToDisplayableProduct(
      { item }, occasion, storeTimeZone
    ))
    .filter(isDisplayableProduct);
};

const itemsQueryTemplate = (itemId?: string, index?: number) => (`
  item${index}: item(itemID: "${itemId}") {
    ...ItemFragment
    modifiers {
      name
      type
      id
      priority
      modifiers {
        ...ItemFragment
        modifiers {
          name
          type
          id
          priority
          ...ItemFragment
        }
      }
    }
  }
`);

const generateItemList = (items: DealRecipe[]): string => {
  let itemsList = '';
  items.forEach(({ fullId }, index) => {
    itemsList += itemsQueryTemplate(fullId, index);
    return null;
  });

  return itemsList;
};

const query = (recipes: DealRecipe[]): () =>
TypedQueryWithParser<
DisplayableProduct[],
ProductsByIdQueryResponse,
CheckAvailabilityVariables
> => {
  const ItemsList = generateItemList(recipes);
  return () => ({
    query: gql`
      query getProductsById($storeID: String!) {
        Homepage: store(storeID: $storeID) {
          menu {
            ${ItemsList}
          }
        }
      }
      ${ItemFragment}
    `,
    parser
  });
};

export default query;
