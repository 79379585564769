import { batch, useDispatch, useSelector } from 'react-redux';
import { dealSelectors } from '../slice/deal.slice';
import { updateDealRecipes } from '../slice/userSelections.slice';
import { Product } from '@/domain/product/types';
import { AddableCartItemKinds, DealRuleTypes } from '@/api/phdApiV2Client/request.types';
import { Deal, DealRecipe, DealStep } from '../slice/dealTypes';
import RuleAction from '@/common/RuleAction';
import { autoAddingRecipeIds, optimizelyRecipeIds } from './constants';
import { RootState } from '@/rootStateTypes';

export const isAutoAddStep = (step: DealStep, multiStepDecision: boolean): boolean => {
  const isAutoAddStepConfirm = step.recipeOptions.filter((option) => (
    multiStepDecision ? [...autoAddingRecipeIds, ...optimizelyRecipeIds] : autoAddingRecipeIds).includes(option.id));
  return isAutoAddStepConfirm.length > 0;
};

export const hasUpgrade = (step: DealStep, multiStepDecision: boolean): boolean => isAutoAddStep(step, multiStepDecision) && step.recipeOptions.length > 1;

export const useAutoAddToDeal = (multiStepDecisionEnabled: boolean): void => {
  const dealData = useSelector(dealSelectors.selectDeal);
  const dispatch = useDispatch();
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const addProductToDeal = (recipeOption: DealRecipe, ruleType: string, ruleId: string, stepId: string, index: number) => {
    const {
      fullId, type, name, description, sodiumWarning, displayableProduct
    } = recipeOption;

    const modifierId = `${fullId}/${ruleType === 'SIZE' ? DealRuleTypes.SIZE : ''}/${ruleId}`;
    const item: Product = {
      kind: AddableCartItemKinds.PRODUCT,
      type,
      id: fullId,
      name,
      description,
      quantity: 1,
      sodiumWarning,
      selectedModifiers: [
        {
          id: modifierId,
          name: '',
          type: ruleType,
          sodiumWarning,
          variantCode: displayableProduct?.sizes?.[0].variantCode
        }
      ]
    };

    const dispatchUpdateDealRecipe = () => dispatch(updateDealRecipes([{ item, stepId, index }]));
    batch(() => {
      dispatchUpdateDealRecipe();
    });
  };

  if (dealData) {
    const { data: deals, userSelections }: Deal = dealData;
    const { steps, rules } = deals;
    const { recipes } = userSelections.present;
    if (!rules[RuleAction.INCLUDE]) return;
    const includeRules = rules[RuleAction.INCLUDE]; // Get all the include Rules
    // End Data

    if (recipes.length === 0 && includeRules) {
      // Filters Deal Steps by recipe option ids that match
      const stepsToAdd = steps.filter(
        (step) => step.recipeOptions.flatMap(
          (flatStep) => flatStep
        ).find((
          (recipe) => (multiStepDecisionEnabled ? [...autoAddingRecipeIds, ...optimizelyRecipeIds] : autoAddingRecipeIds).includes(recipe.id)
        ))
      );

      // Build product from steps and matching rules.
      stepsToAdd.forEach((step) => {
        // Extract correct Rules
        const { recipeOptions } = step;
        const index = steps.indexOf(step);

        if (isYumEcomm) {
          // Rules don't exist on Yum so we can skip the rule check
          if (recipeOptions?.length > 0) {
            addProductToDeal(recipeOptions[0], DealRuleTypes.SIZE, '', step.id, index);
          }
          return;
        }

        const rule = includeRules.find(
          (includedRule) => includedRule.type === 'SIZE' && includedRule.stepId === step.id
        );

        if (recipeOptions?.length > 0 && rule) {
          const { type: ruleType, id: ruleId } = rule;
          addProductToDeal(recipeOptions[0], ruleType, ruleId, step.id, index);
        }
      });
    }
  }
};
