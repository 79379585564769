import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useDeepEffect from '../../../common/useDeepEffect';
import { dealSelectors } from '../slice/deal.slice';
import { userSelectionsSelector } from '../slice/userSelections.slice';
import { CurrentStep } from '../slice/dealTypes';
import DealStep from './DealStep';
import { initialDealBuilderAnalytics, onMultiStepDealRenderAnalytics } from '@/builders/deals/analytics';
import { DealDetails } from '../DealDetails/DealDetails';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { useAutoAddToDeal } from '../hooks/useAutoAddingDealHelpers';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';

interface Props {
  isEditFlow?: boolean;
}

function exists(currentStep: CurrentStep | null): boolean {
  return !!(currentStep?.type && currentStep?.id);
}

const DealStepToRender = ({ isEditFlow = false }: Props): JSX.Element | null => {
  const [analyticsSent, setAnalyticsSent] = useState(false);
  const currentStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const deal = useSelector(dealSelectors.selectDealSummary, shallowEqual);
  const analytics = useAnalytics();
  const hasCurrentStep = exists(currentStep);
  const dealId = deal?.id;
  const [decisionAnalyticsSent, setDecisionAnalyticsSent] = useState(false);
  const [multiStepDecision] = useTrackedDecision('exp-abt-58-multi-step-builder-simplification', { skipAnalytics: decisionAnalyticsSent });

  useAutoAddToDeal(multiStepDecision.enabled);
  useEffect(() => {
    if (!decisionAnalyticsSent) {
      setDecisionAnalyticsSent(true);
    }
  }, [decisionAnalyticsSent]);

  const pushDealBuilderAnalytics = () => {
    if (dealId && !analyticsSent) {
      analytics.push(() => initialDealBuilderAnalytics(deal, currentStep));
      if (!hasCurrentStep) {
        analytics.push(() => onMultiStepDealRenderAnalytics(deal));
      }
      setAnalyticsSent(true);
    }
  };

  useEffect(() => {
    pushDealBuilderAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return useMemo(() => {
    if (hasCurrentStep) {
      return <DealStep step={currentStep} isEditFlow={isEditFlow} />;
    }

    return <DealDetails isEditFlow={isEditFlow} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, hasCurrentStep]);
};

export default DealStepToRender;
