import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { selectors } from '../../pizza/slice/pizza.slice';
import useDealValidator from '../hoc/useDealValidator/useDealValidator';
import useStyles from './styles';

import useAddToDeal from '../hooks/useAddToDeal';

const AddToDealButton = (): JSX.Element | null => {
  const buttonText = 'add to deal';
  const classes = useStyles();
  const pizza = useSelector(selectors.selectCartAddablePizza);
  const addToDeal = useAddToDeal();

  const showAddButton = useDealValidator();

  if (!showAddButton) {
    return null;
  }

  return (
    <Button
      data-testid="add-to-deal-button"
      className={classes.addBtn}
      onClick={() => addToDeal(pizza)}
      variant="contained"
      color="primary"
      aria-label={buttonText}
    >
      {buttonText}
    </Button>
  );
};

export default AddToDealButton;
