import { useDispatch } from 'react-redux';
import { openLocalizationRail, switchToDelivery } from '@/localization/actions';
import { Occasion, OccasionString } from '@/localization/constants';
import Routes from '@/router/routes';
import { setDealToDisplay } from '@/builders/deals/slice/dealToDisplay.slice';

export type ModalType = Pick<ModalContent, 'title' | 'body' | 'cta' | 'altCta'>;

class AvailableForOtherOccasion {
  public occasion: Occasion;

  public modalDetails: ModalType;

  private otherOccasion = {
    [Occasion.CARRYOUT]: 'DELIVERY',
    [Occasion.DELIVERY]: 'CARRYOUT'
  };

  constructor(occasion: Occasion, callback: () => void) {
    this.occasion = occasion;
    this.modalDetails = {
      title: this.getTitle(),
      body: this.getBody(),
      cta: {
        text: this.getCtaText(),
        callback
      },
      altCta: {
        text: 'CONTINUE SHOPPING'
      }
    };
  }

  getTitle() {
    const otherOccasion = this.getOtherOccasionString().toLowerCase();
    return `This deal is for ${otherOccasion} only`;
  }

  getBody() {
    const currentOccasion = OccasionString[this.occasion].toLowerCase();
    const otherOccasion = this.getOtherOccasionString().toLowerCase();
    return `The deal you selected isn't available for ${currentOccasion}. Do you want to switch to ${otherOccasion}? If you choose to switch, your cart may be emptied.`;
  }

  getCtaText() {
    const otherOccasion = this.getOtherOccasionString().toUpperCase();
    return `SWITCH TO ${otherOccasion}`;
  }

  getOtherOccasionString() {
    return this.otherOccasion[this.occasion];
  }
}

interface Props {
  currentOccasion: Occasion;
  publicCode: string | null | undefined;
  privateCode: string | null | undefined;
}

type HookType = (props: Props) => ModalType;
const useDealOtherOccasionModal = (): HookType => {
  const dispatch = useDispatch();

  return (props: Props): ModalType => {
    const { publicCode, privateCode, currentOccasion } = props;
    const routeTo = `${Routes.DEALS}?id=${publicCode}`;
    const handleAfterLocalizing = () => {
      if (privateCode) dispatch(setDealToDisplay({ dealId: privateCode }));
    };

    const { onlyPublic, onlyPrivate, both } = {
      onlyPublic: { routeTo },
      onlyPrivate: { handleAfterLocalizing },
      both: { routeTo, handleAfterLocalizing }
    };

    const ctaCallback = () => {
      let prop;
      if (publicCode && !privateCode) prop = onlyPublic;
      if (!publicCode && privateCode) prop = onlyPrivate;
      if (publicCode && privateCode) prop = both;

      dispatch(openLocalizationRail(prop));
      if (currentOccasion === Occasion.CARRYOUT) {
        dispatch(switchToDelivery());
      }
    };

    const { modalDetails } = new AvailableForOtherOccasion(currentOccasion, ctaCallback);
    return modalDetails;
  };
};

export default useDealOtherOccasionModal;
