import { useDispatch, useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CustomQueryOptions } from '../types/QueryOptions';
import { QueryFunctionWithParser, VersionQuery, useVersionQuery } from './useVersionQuery';
import useCheckAvailabilityVariables,
{ CheckAvailabilityVariables } from './variables/useCheckAvailabilityVariables';
import { ProductDomain } from './variables/useProductItem';
import { selectLocalizationInitialized, selectLocalizedStore } from '@/localization/selectors';
import { hideLoadingQuery, showLoadingQuery } from '@/localization/actions';

interface VersionQueryWithAvailability<
  ParsedResultType,
  RawQueryResultType,
  ParserProps
> {
  queryFn: QueryFunctionWithParser<
  ParsedResultType,
  RawQueryResultType,
  ParserProps
  >;
  storeSpecific?: boolean;
  itemId?: string;
  options?: CustomQueryOptions;
  nationalOverrides?: boolean;
  productDomain?: ProductDomain;
  parserOptions?: ParserProps;
}

export interface CheckAvailabilityLoading {
  storeID: string | null;
  loading: true;
  data: undefined;
  error: undefined;
}

export function useVersionQueryWithAvailability<
  ParsedResultType,
  RawQueryResultType,
  ParserProps = void
>({
  queryFn,
  storeSpecific = false,
  itemId,
  options,
  nationalOverrides = false,
  productDomain,
  parserOptions
}: VersionQueryWithAvailability<
ParsedResultType,
RawQueryResultType,
ParserProps
>): VersionQuery<ParsedResultType> | CheckAvailabilityLoading {
  const availabilityOptions = useCheckAvailabilityVariables();
  const localizationInitialized = useSelector(selectLocalizationInitialized);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const localizedStore = useSelector(selectLocalizedStore);

  useEffect(() => {
    dispatch(isLoading ? showLoadingQuery() : hideLoadingQuery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // eslint-disable-next-line prefer-object-spread
  const decoratedParserOptions = Object.assign(
    {},
    parserOptions,
    availabilityOptions
  );
  const result = useVersionQuery<
  ParsedResultType,
  RawQueryResultType,
  ParserProps & CheckAvailabilityVariables
  >(
    {
      queryFn,
      storeSpecific,
      itemId,
      options,
      nationalOverrides,
      productDomain,
      parserOptions: decoratedParserOptions
    }
  );

  let parsed: VersionQuery<ParsedResultType> | CheckAvailabilityLoading = result;
  if (!localizationInitialized) {
    parsed = {
      storeID: null,
      loading: true,
      data: undefined,
      error: undefined
    };
  } else if (!localizedStore?.storeToken) {
    parsed = {
      ...result,
      loading: false,
      error: new ApolloError({
        errorMessage: 'Store token is missing.'
      })
    };
  }

  if (parsed.loading !== isLoading) {
    setLoading(parsed.loading);
  }

  return parsed;
}
