import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { CartItem as CCCartItem, CartModifier } from '@pizza-hut-us-development/client-core/lib/esm/types/order/orderTypes';
import { useDecision } from '@optimizely/react-sdk';
import {
  updatePizzaOptions,
  updatePizzaWithUserSelections
} from '../../pizza/slice/pizza.slice';
import usePizzaOptions from '../../pizza/hooks/usePizzaOptions';
import { userSelectionsSelector } from '../slice/userSelections.slice';
import useApplyDealRules from './useApplyDealRules';
import useDeepEffect from '@/common/useDeepEffect';
import { BuiltPizza, DisplayableItem, PizzaUserSelections } from '@/builders/pizza/dataTransformers/builderTypes';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import { openModal } from '@/localization/actions';
import Routes from '@/router/routes';
import { dealDataOOSModalDetails, RETURN_TO_DEALS } from '@/common/Modal/modalDetails';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import { RootState } from '@/rootStateTypes';
import {
  extractYumPizzaUserSelections
} from '@/clientCore/temporaryTransformationalHooks/extractYumPizzaUserSelections/extractYumPizzaUserSelections';
import useCCCartState from '@/clientCore/cart/hooks/useCCCartState';
import { CurrentStep } from '@/builders/deals/slice/dealTypes';

const composeUserSelectionsObject = (recipe: BuiltPizza): Partial<PizzaUserSelections> => ({
  finisher: recipe.finisher,
  cheese: recipe.cheese,
  sauce: recipe.sauce,
  crust: recipe.crust,
  size: recipe.size,
  specialInstructions: recipe.specialInstructions,
  meatToppings: recipe.meatToppings,
  veggieToppings: recipe.veggieToppings
});

const outOfStock = (options: DisplayableItem[]) => options
  .reduce((acc, current) => !!current.outOfStock && acc, true);

function usePizzaDataInRedux(): boolean {
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );

  const [openModalDispatched, setOpenModalDispatched] = useState(false);
  const dispatch = useDispatch();
  const [{ enabled: yumEditDupeStepsEnabled }] = useDecision('fr-web3476-yum_edit_deals_duplicate_steps');
  const router = useRouter();
  const currentDealStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const pizzaId = useProduct('items', currentDealStep?.recipeId ?? undefined)?.variables?.itemID;
  const { selectedItem, isEditingFlow } = useCCCartState();
  const { pizzaOptions: gqlPizzaOptions, loading: gqlLoading } = usePizzaOptions({
    idOrCode: {
      pizzaGlobalId: pizzaId ?? undefined,
      productCode: ''
    },
    skipQuery: isYumEcomm
  });
  const [{ enabled: frozenEditPizzaBuilderDecision }] = useDecision(('fr-web-3454-fix-frozen-edit-pizza-builder-yum'));
  const { pizzaOptions, loading } = isYumEcomm
    ? { pizzaOptions: currentDealStep?.recipeOptions.find((pizza) => pizza.id === currentDealStep?.recipeId)?.pizzaBuilderOptions ?? null, loading: false }
    : { pizzaOptions: gqlPizzaOptions, loading: gqlLoading };

  const options = useApplyDealRules(pizzaOptions);
  const recipe = useSelector(userSelectionsSelector.selectCurrentStepDealRecipe);
  const getCurrentStepFromYumDeal = (selectedDeal: CCCartItem, selectedDealStep: CurrentStep): CCCartItem => {
    let dealStepPizza: CartModifier | undefined;
    if (yumEditDupeStepsEnabled) {
      dealStepPizza = selectedDeal?.modifiers?.find((step, index) => {
        if ((step?.quantity ?? 1) > 1) {
          return step.id === selectedDealStep?.id;
        }
        return step.id === selectedDealStep?.id && index === selectedDealStep?.index;
      });
    } else {
      dealStepPizza = selectedDeal?.modifiers?.find(
        (step, index) => step.id === selectedDealStep?.id && index === selectedDealStep?.index
      );
    }
    return dealStepPizza?.modifiers?.find((pizza) => pizza.id === selectedDealStep.recipeId) as CCCartItem;
  };

  useEffect(() => {
    if (options && !openModalDispatched) {
      const { sizes, crusts } = options;

      const outOfStockSizes = sizes && outOfStock(sizes);
      const outOfStockCrusts = crusts && outOfStock(crusts);

      if (outOfStockSizes || outOfStockCrusts) {
        dispatch(openModal({
          ...dealDataOOSModalDetails,
          cta: {
            text: RETURN_TO_DEALS,
            callback: () => {
              setOpenModalDispatched(true);
              router.push(Routes.DEALS);
            }
          },
          hideCloseIcon: true
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useDeepEffect(() => {
    if (options) {
      dispatch(updatePizzaOptions(options));
      if (recipe?.item && recipe?.item.kind === AddableCartItemKinds.BUILT_PIZZA) {
        if (frozenEditPizzaBuilderDecision) {
          const { crust, ...userSelections } = composeUserSelectionsObject(recipe.item);
          if (isYumEcomm && !crust) {
            dispatch(updatePizzaWithUserSelections(userSelections));
          } else {
            dispatch(updatePizzaWithUserSelections({ crust, ...userSelections }));
          }
        } else {
          const userSelections = (isYumEcomm && isEditingFlow && currentDealStep && selectedItem && pizzaOptions)
            ? extractYumPizzaUserSelections(getCurrentStepFromYumDeal(selectedItem as CCCartItem, currentDealStep), pizzaOptions)
            : composeUserSelectionsObject(recipe.item);
          dispatch(updatePizzaWithUserSelections(userSelections));
        }
      }
    }
  }, [options, recipe]);
  return loading;
}

export default usePizzaDataInRedux;
