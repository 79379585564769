import { createStyles, makeStyles, Theme } from '@material-ui/core';
import fontStyles from '../../../common/fontStyles';
import colors from '../../../common/colors';
import { mobileStartBreakpoint } from '../../../materialUi/theme';

export default makeStyles((theme: Theme) => createStyles({
  addButtonGrid: {
    width: '100%'
  },
  addBtn: {
    ...fontStyles.primaryCtaLabel,
    'text-transform': 'uppercase',
    textAlign: 'center',
    fontSize: '16px',
    width: '252px',
    padding: '6px 0 7px 0',
    border: `solid 1px ${colors.red}`,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '14px',
      width: '100%',
      minWidth: '252px',
      height: '32px'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '252px'
    }
  },
  addToCart: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));
